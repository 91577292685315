import React from 'react'
import { graphql } from 'gatsby'
import lang from '../data/es.json'
import ProjectComponent from '../components/project'
import withWindowSize from '../components/withWindowSize'

const Project = props => (
  <ProjectComponent {...props} lang={lang} />
)

export const query = graphql`
  query ProjectEsQuery($slug: String!) {
    logo: imageSharp(fluid: { originalName: { eq: "logo.png" }}) {
      sizes(quality: 100, maxWidth: 75, maxHeight: 50, cropFocus: CENTER){
        ...GatsbyImageSharpSizes_withWebp
      }
    }
    allImageSharp(
      filter: { fluid: { originalName: { glob: "{bg,project,smallProject,phone}*.png" } } }
    ) {
      nodes {
        sizes(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpSizes_withWebp
          originalImg
          originalName
        }
      }
    }
    sitePage(context: { slug: { eq: $slug }, isEn: { eq: false } }) {
      id
      context {
        projectData {
          name {
            fullName
            white
            blue
          }
          year
          description
          image
          technologies
          website
          video
          path
          flag
        }
      }
    }
    allSitePage(
      sort: {fields: context___projectData___index},
      filter: { component: { glob: "**/project.es.js" }}
    ) {
      edges {
        next {
          path
        }
        previous {
          path
        }
        node {
          id
        }
      }
    }
  }
`

export default withWindowSize(Project)
